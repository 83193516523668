import { useSubmit } from '@remix-run/react';
import { SubmitOptions } from 'react-router-dom/dist/dom';
import { transferFormData } from '@common/tranfer.utils';

type useCustomSubmitProps = {
    action?: string;
    actionKey?: string;
};

export function useCustomSubmit<T extends Record<string, any>>({
    action = '',
    actionKey = 'default',
}: useCustomSubmitProps = {}) {
    const submitEvent = useSubmit();

    const submitOptions = getSubmitOptions(action);
    const multipartOptions = getSubmitMultiPartOptions(action);

    const multipartSubmit = (data: T, submitActionKey?: string, options?: SubmitOptions) => {
        const formData = data instanceof FormData ? data : transferFormData(data);

        formData.append('_action', submitActionKey ? submitActionKey : actionKey);
        submitEvent(formData, {
            ...multipartOptions,
            ...options,
        });
    };

    const submit = (data: T | null, submitActionKey?: string, options?: SubmitOptions) => {
        const option = options ? options : submitOptions;

        submitEvent(
            {
                ...(data ? data : {}),
                _action: submitActionKey ? submitActionKey : actionKey,
            },
            {
                ...option,
            },
        );
    };

    return {
        submit,
        multipartSubmit,
        submitOptions,
        multipartOptions,
    };
}

const getBaseOption = (action: string): SubmitOptions => {
    return {
        method: 'post',
        action: action,
        navigate: true,
    };
};

const getSubmitOptions = (action: string): SubmitOptions => {
    return {
        ...getBaseOption(action),
        encType: 'application/json',
    };
};

const getSubmitMultiPartOptions = (action: string): SubmitOptions => {
    return {
        ...getBaseOption(action),
        encType: 'multipart/form-data',
    };
};
